<template>
  <header class="navbar navbar-expand-lg p-2 fixed-top navbar-dark bg-dark text-light">
    <div class="container-xxl">

      <a class="navbar-toggler navbar-nav" data-bs-toggle="offcanvas" data-bs-target="#sidebarRightUtp1"
         aria-controls="sidebarRightUtp1" aria-expanded="false">
        <i class="nav-link bi bi-list fs-2"></i>
      </a>

      <ul class="navbar-nav align-items-center">
        <li>
          <a @click="$router.push('/')">
            <img height="42" src="../../assets/images/logo.svg" alt="UTP1 logo">
          </a>
        </li>
        <li class="navbar-text small ps-2 d-none d-lg-block">
          demo v1.7.3
        </li>
      </ul>

      <a class="navbar-toggler navbar-nav" data-bs-toggle="offcanvas" data-bs-target="#navbarUtp1"
         aria-controls="navbarUtp1" aria-expanded="false">
        <i class="nav-link bi bi-three-dots fs-2"></i>
      </a>

      <div class="offcanvas offcanvas-end bg-dark" id="navbarUtp1" aria-labelledby="navbarUtp1Label">

        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="navbarUtp1Label"></h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1">
            <li class="nav-item dropdown">
              <a href="#" class="nav-link d-flex align-items-center dropdown-toggle"
                 id="dropdownUserSettings" data-bs-toggle="dropdown" aria-expanded="false">
                <!--suppress JSUnresolvedVariable -->
                <span class="me-2">{{ $store.state.auth.name }}</span>
                <!--suppress CheckImageSize, JSUnresolvedVariable -->
                <img v-if="$store.state.avatar.avatar" :src="'data:image;base64,' + $store.state.avatar.avatar"
                     :alt="$store.state.auth.name" width="42" height="42"
                     class="rounded-circle">
                <!--suppress CheckImageSize, JSUnresolvedVariable -->
                <img v-else src="../../assets/images/gravatar.jpg"
                     :alt="$store.state.auth.name" width="42" height="42"
                     class="rounded-circle">
              </a>
              <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end p-2 rounded-3 m-0"
                  aria-labelledby="dropdownUserSettings">
                <li><a @click="redirect('/')" class="dropdown-item rounded-2">Основная страница</a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a @click="redirect('/settings')" class="dropdown-item rounded-2">Настройки профиля</a></li>
                <li><a @click="redirect('/settings/security')" class="dropdown-item rounded-2">Безопасность</a></li>
                <li><a class="dropdown-item disabled" disabled>Помощь</a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a @click="logout" class="dropdown-item rounded-2" data-bs-dismiss="offcanvas">Выход</a></li>
              </ul>
            </li>
          </ul>
          <div class="d-block d-lg-none">
            <hr>
            <footer-content-right/>
            <footer-content-left/>
          </div>

        </div>

      </div>
    </div>

  </header>
</template>

<script>
import FooterContentRight from "./footer/FooterContentRight";
import FooterContentLeft from "./footer/FooterContentLeft";
import useTokens from "../../hooks/useTokens";
import PreloaderFull from "./preloader/PreloaderFull";
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";

export default {
  name: "navbar-utp1",
  components: {PreloaderFull, FooterContentLeft, FooterContentRight},
  setup() {
    const router = useRouter()

    const {logout} = useTokens()

    const navbarUtp1 = ref()

    onMounted(() => {
      navbarUtp1.value = new bootstrap.Offcanvas('#navbarUtp1')
    })

    const redirect = async (page) => {
      await router.push(page)
      navbarUtp1.value.hide()
    }

    return {logout, redirect}
  }
}
</script>

<style scoped>
@import "../../assets/css/navbar.css";
</style>
